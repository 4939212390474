var FAQItemController = (function($) {

    var faqItems;

    function onDocReady() {

        faqItems = $(".faq-item");

        if(faqItems.length) {
            $(".faq-item .faq-title").click(faqToggle);
        }
    }

    function faqToggle() {
        var item = $(this).closest(".faq-item");

        if(item.hasClass("faq-open")) {
            item.removeClass("faq-open");
            item.find(".faq-content").slideUp(200);
        }
        else {
            item.addClass("faq-open");
            item.find(".faq-content").slideDown(200);
        }
    }


    $(onDocReady);

    return {};

})(jQuery);
var FontSizeController = (function ($) {

    var _storageKey = 'txt_sizer_value';

    function onDocReady() {
        $(".txt-resize-btn.decrease").click(decreaseFontSize);
        $(".txt-resize-btn.increase").click(increaseFontSize);
        getSettings();
    }

    function increaseFontSize(e) {
        e.preventDefault();
        var html = $(('html'));

        if(html.hasClass("text-large")) {
            html.removeClass("text-large");
            html.addClass("text-larger");
            saveSetting("text-larger");
        }
        else {
            if(html.hasClass("text-larger")) {
                html.removeClass("text-larger");
                html.addClass("text-largest");
                saveSetting("text-largest");
            }
            else {
                if(!html.hasClass("text-largest")) {
                    html.addClass("text-large");
                    saveSetting("text-large");
                }
            }
        }
    }

    function decreaseFontSize(e) {
        e.preventDefault();
        var html = $(('html'));

        if(html.hasClass("text-large")) {
            html.removeClass("text-large");
            localStorage.removeItem(_storageKey);
        }
        else {
            if(html.hasClass("text-larger")) {
                html.removeClass("text-larger");
                html.addClass("text-large");
                saveSetting("text-large");
            }
            else {
                if(html.hasClass("text-largest")) {
                    html.removeClass("text-largest");
                    html.addClass("text-larger");
                    saveSetting("text-larger");
                }
            }
        }
    }

    function saveSetting(selector) {
        if (!supported()) return;
        localStorage.setItem(_storageKey, selector);
    }

    function getSettings() {
        if (!supported()) return;

        var stored = localStorage.getItem(_storageKey);
        if (stored) {
            $(('html')).addClass(stored);
        }
    }

    function supported() {
        var testKey = 'test', storage = window.sessionStorage;
        try {
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);
            return true;
        } catch (error) {
            return false;
        }
    }

    $(onDocReady);

    return {};

})(jQuery);
